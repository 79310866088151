import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import CustomButton from 'component/base/CustomButton';

import { createAction, validateEmail } from 'utils';
import {
  CUSTOMER_SIGN_UP_METHOD,
  SupportedCountryCodeList,
} from 'utils/CustomEnums';

import { SHOW_ONLINE_EMAIL_REMINDER, SECOND_CONTACT_FOR_LOGIN } from 'utils/Config';

import './LoginMethodContactModal.scss';

const sections = SupportedCountryCodeList.map((item) => ({
  name: item,
  pk: item,
}));

const MaxTimeCount = 599;

const mapStateToProps = ({ users, userInfoTemp }) => {
  return {
    language: users.language,
    signUpMethod: users.userInfo.signUpMethod,
    contactError: userInfoTemp.loginMethodContact.error,
    contactErrorMessage: userInfoTemp.loginMethodContact.errorMessage,
    verifyChallenge: userInfoTemp.verifyChallenge,
    verifyError: userInfoTemp.verifyError,
  };
};

const LoginMethodContactModal = ({
  language,
  signUpMethod,
  contactError,
  contactErrorMessage,
  verifyChallenge,
  verifyError,
  showModal,
  onClose = () => { },
}) => {
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState(SupportedCountryCodeList?.[0]);
  const selectCountryCode = useCallback((value) => setCountryCode(value), []);
  const [nationalNumber, setNationalNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [requiringOPT, setRequiringOPT] = useState(false);
  const [verifyCode, setVerifyCode] = useState(null);

  // timer
  const [refreshDatetime, setRefreshDatetime] = useState(Date.now());
  useEffect(() => {
    setRefreshDatetime(Date.now());
    const intervalID = setInterval(() => {
      setRefreshDatetime(Date.now());
    }, 100);
    return () => {
      // cancel refresh qrcode
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [callOTPTime, setCallOTPTime] = useState(null);

  const method = useMemo(() => {
    if (signUpMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
      return CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER;
    } else if (signUpMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
      return CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS;
    }
    return null;
  }, [signUpMethod]);

  const verifyEnable = useMemo(() => {
    const isValidEmail = validateEmail(email)
    if (!isValidEmail) {      
      return false;
    }
    if (requiringOPT) {
      return false;
    }
    if (callOTPTime !== null) {
      const ms = Number(refreshDatetime) - Number(callOTPTime);
      const seconds = Math.round(ms / 1000);
      if (seconds < MaxTimeCount) {
        return false;
      }
    }
    return true;
  }, [requiringOPT, refreshDatetime, callOTPTime, email]);

  const verifyText = useMemo(() => {
    if (callOTPTime !== null) {
      const ms = Number(refreshDatetime) - Number(callOTPTime);
      const seconds = Math.round(ms / 1000);
      if (seconds < MaxTimeCount) {
        return i18n.t('account_verify') + ` (${MaxTimeCount - seconds}s)`;
      }
    }
    return i18n.t('account_verify');
  }, [refreshDatetime, callOTPTime]);

  const confirmEnable = useMemo(
    () => verifyChallenge !== null && verifyCode !== null,
    [verifyCode, verifyChallenge],
  );

  useEffect(() => {
    dispatch(createAction('userInfoTemp/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, countryCode, nationalNumber]);

  useEffect(() => {
    setEmail(null);
    setCountryCode(SupportedCountryCodeList?.[0]);
    setNationalNumber(null);
    setVerifyCode(null);
    setCallOTPTime(null);
    dispatch(createAction('userInfoTemp/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const callApiRequireOTP = () => {
    setRequiringOPT(true);
    dispatch(
      createAction('userInfoTemp/requireOTPForUpdateLoginMethod')({
        method: CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        countryCode,
        nationalNumber,
        email,
        successCallback: () => {
          setRequiringOPT(false);
          setCallOTPTime(Date.now());
        },
        failureCallback: () => setRequiringOPT(false),
      }),
    );
  };

  const callApiUpdateLoginMethod = () => {
    dispatch(
      createAction('userInfoTemp/verifyOTPAndUpdateLoginMethod')({
        method: CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        countryCode,
        nationalNumber,
        email,
        verifyCode,
        successCallback: () => {
          onClose();
        },
        failureCallback: () => { },
      }),
    );
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={onClose}
      scrollable={false}
      className={'login-method-modal'}
      centered
    >
      <Modal.Body className={'login-method-modal-body '}>
        <div className="home-profile-custom-title title-medium-1">
          <div>
            {SECOND_CONTACT_FOR_LOGIN ? (i18n.t('settings_login_and_contact') +
              i18n.t('personal_info_optional')) : i18n.t('additional_contact_title')}
          </div>
          <div className="close-icon" onClick={onClose}>
            <img
              alt="close"
              src="/profile/close.png"
              srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
            />
          </div>
        </div>
        <div className="account-error" />
        {method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS ? (
          <div className={contactError ? 'has-error' : ''}>
            <div className="home-profile-custom-email bottom-line common-item-value field-text">
              <input
                placeholder={i18n.t('settings_email')}
                className="email-input field-text"
                onChange={(e) => {
                  const text = e.target.value;
                  setEmail(text);
                }}
              />
              <CustomButton
                className="button-small"
                disabled={!verifyEnable}
                text={verifyText}
                btnClicked={() => callApiRequireOTP()}
              />
            </div>
            {SHOW_ONLINE_EMAIL_REMINDER ?
              <div className="account-common-prompt-information tag-menu-1">
                {i18n.t('settings_email_tip')}
              </div> : null
            }

          </div>
        ) : null}
        {method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER ? (
          <div
            className={
              contactError
                ? 'home-profile-mobile has-error'
                : 'home-profile-mobile'
            }
          >
            <div className="home-profile-custom-mobile country-code bottom-line common-item-value field-text">
              <GenderPicker
                customClass={'country-code-picker-box'}
                sections={sections}
                value={countryCode}
                selectValue={selectCountryCode}
              />
            </div>
            <div className="home-profile-custom-mobile national-number bottom-line common-item-value field-text">
              <input
                placeholder={i18n.t('account_mobile')}
                className="mobile-input field-text"
                onChange={(e) => {
                  const text = e.target.value;
                  setNationalNumber(text);
                }}
              />
              <CustomButton
                className="button-small"
                disabled={!verifyEnable}
                text={verifyText}
                btnClicked={() => callApiRequireOTP()}
              />
            </div>
          </div>
        ) : null}
        <div className="account-error account-common-prompt-information change-password-error tag-menu-2">
          {contactError ? contactErrorMessage : null}
        </div>
        <div
          className={`home-profile-custom-verify-code bottom-line common-item-value field-text ${verifyError ? 'has-error' : ''
            }`}
        >
          <input
            placeholder={i18n.t('sign_up_verification_code')}
            className="field-text"
            onChange={(e) => {
              const text = e.target.value;
              setVerifyCode(text);
            }}
          />
        </div>
        <div className="account-error account-common-prompt-information change-password-error tag-menu-2">
          {verifyError || ''}
        </div>
        <CustomButton
          className="button-large"
          disabled={!confirmEnable}
          text={i18n.t('account_update')}
          btnClicked={() => callApiUpdateLoginMethod()}
        />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps)(LoginMethodContactModal);
