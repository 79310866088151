import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./RewardStatusSection.scss";
import { withTranslation } from "react-i18next";
import { getLevelRenewTime } from "../../utils/DateTimeUtils";
import { createAction } from "../../utils";
import { Container, Row, Col } from "react-bootstrap";
import CustomModal from "../base/CustomModal";
import RewardStatusTableView, {
  parseCouponListData,
  parsePointsStatus,
  parseStampStatus,
  rewardStatusModalTab
} from "./RewardStatusTableView";
import { NO_LEVEL } from "utils/Config";
import { isPadOrMobile } from "utils/ScreenUtils";

@connect(({ users, myCoupon, pullrefreshandloadmore }) => ({
  levelName: users.levelName,
  levelExpiryDate: users.renewDate,
  pointStatus: users.pointStatus,
  pointStatusNextLink: users.pointStatusNextLink,
  isRefreshingPoints: users.isRefreshingPointsStatus || false,
  couponNextLink: myCoupon.nextLink,
  availableCoupons: myCoupon.coupons,
  ...pullrefreshandloadmore,
  language: users.language,
  isRefreshingStamp: users.isRefreshingStamp,
  totalEarnedStamps: users.totalEarnedStamps,
  stampStatus: users.stampStatus,
  stampStatusNextLink: users.stampStatusNextLink,
}))
class RewardStatusSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      activeTab: NO_LEVEL ? rewardStatusModalTab.points : rewardStatusModalTab.level,
    };
    this.isMobile = isPadOrMobile();
    this.showLevel = !NO_LEVEL
  }
  componentDidMount() {
    this.props.dispatch(createAction("users/fetchRewardStatus")());
  }

  componentWillUnmount() {
    this.props.dispatch(createAction("users/clearRewardStatus")());
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction("users/fetchRewardStatus")());
    }
  }

  loadMore = () => {
    if (this.state.activeTab === rewardStatusModalTab.coupon && this.props.couponNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          MyCouponIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("myCoupon/loadMoreCoupon")());
    }
    if (this.state.activeTab === rewardStatusModalTab.points && this.props.pointStatusNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          PointStatusIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("users/loadMorePointStatus")());
    }
    if (this.state.activeTab === rewardStatusModalTab.stamp && this.props.stampStatusNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          StampStatusIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("users/loadMoreStampStatus")());
    }
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  render() {
    const tabs = [
      ...(this.showLevel ? [{
        key: rewardStatusModalTab.level,
        name: this.props.t(rewardStatusModalTab.level),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.level}
                title={this.props.t("profile_level")}
                itemList={[
                  {
                    name: this.props.levelName,
                    date: `${getLevelRenewTime(
                      this.props.levelExpiryDate
                    )}`,
                  },
                ]}
              />
            </Col>
          </Row>
        )
      }] : []),
      {
        key: rewardStatusModalTab.points,
        name: this.props.t(rewardStatusModalTab.points),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.points}
                title={this.props.t("profile_points")}
                itemList={parsePointsStatus(this.props.pointStatus)}
                isLoading={this.props.isRefreshingPoints}
                isLoadingMore={this.props.PointStatusIsLoadingMore || false}
                loadMore={this.loadMore}
              />
            </Col>
          </Row>
        )
      },
      {
        key: rewardStatusModalTab.coupon,
        name: this.props.t(rewardStatusModalTab.coupon),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.coupon}
                title={this.props.t("profile_coupons")}
                itemList={parseCouponListData(this.props.availableCoupons)}
                isLoading={this.props.MyCouponIsRefreshing || false}
                isLoadingMore={this.props.MyCouponIsLoadingMore || false}
                loadMore={this.loadMore}
              />
            </Col>
          </Row>
        )
      },
      // {
      //   key: rewardStatusModalTab.stamp,
      //   name: this.props.t(rewardStatusModalTab.stamp),
      //   content: (
      //     <Row >
      //       <Col>
      //         <RewardStatusTableView
      //           type={rewardStatusModalTab.stamp}
      //           title={this.props.t("profile_stamps")}
      //           itemList={parseStampStatus(this.props.stampStatus)}
      //           isLoading={this.props.isRefreshingStamp || false}
      //           isLoadingMore={this.props.StampStatusIsLoadingMore || false}
      //           loadMore={this.loadMore}
      //           totalEarnedStamps={this.props.totalEarnedStamps}
      //         />
      //       </Col>
      //     </Row>
      //   )
      // }
    ]
    const RewardStatusMobile = () => {
      return (
        <Container className="reward-status-section mobile-view">
          <div className="section-header headline">
            <div>
              {this.props.t("profile_reward_status")}
            </div>
            <div className="view-all button-small" onClick={() => this.props.history.push("/reward_status")}>
              {this.props.t("viewAll")}
            </div>
          </div>
          {this.showLevel &&
            <Row >
              <Col>
                <RewardStatusTableView
                  type={rewardStatusModalTab.level}
                  title={this.props.t("profile_level")}
                  itemList={[
                    {
                      name: this.props.levelName,
                      date: `${getLevelRenewTime(
                        this.props.levelExpiryDate
                      )}`,
                    },
                  ]}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.points}
                title={this.props.t("profile_points")}
                itemList={parsePointsStatus(this.props.pointStatus)}
                isSimple={true}
                isLoading={this.props.isRefreshingPoints}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.coupon}
                title={this.props.t("profile_coupons")}
                itemList={parseCouponListData(this.props.availableCoupons)}
                isSimple={true}
                isLoading={this.props.MyCouponIsRefreshing || false}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.stamp}
                title={this.props.t("profile_stamps")}
                itemList={parseStampStatus(this.props.stampStatus)}
                isSimple={true}
                isLoading={this.props.isRefreshingStamp || false}
                totalEarnedStamps={this.props.totalEarnedStamps}
              />
            </Col>
          </Row> */}
        </Container>
      );
    }

    const RewardStatusDesktop = () => {
      return (
        <Container className="reward-status-section">
          <div className="section-header headline">
            <div>
              {this.props.t("profile_reward_status")}
            </div>
            <div className="view-all button-small" onClick={() => this.setState({ showModal: true })}>
              {this.props.t("viewAll")}
            </div>
          </div>
          {this.showLevel &&
            <Row >
              <Col>
                <RewardStatusTableView
                  type={rewardStatusModalTab.level}
                  title={this.props.t("profile_level")}
                  itemList={[
                    {
                      name: this.props.levelName,
                      date: `${getLevelRenewTime(
                        this.props.levelExpiryDate
                      )}`,
                    },
                  ]}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.points}
                title={this.props.t("profile_points")}
                itemList={parsePointsStatus(this.props.pointStatus)}
                isSimple={true}
                isLoading={this.props.isRefreshingPoints}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.coupon}
                title={this.props.t("profile_coupons")}
                itemList={parseCouponListData(this.props.availableCoupons)}
                isSimple={true}
                isLoading={this.props.MyCouponIsRefreshing || false}
              />
            </Col>
            {/* <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.stamp}
                title={this.props.t("profile_stamps")}
                itemList={parseStampStatus(this.props.stampStatus)}
                isSimple={true}
                isLoading={this.props.isRefreshingStamp || false}
                totalEarnedStamps={this.props.totalEarnedStamps}
              />
            </Col> */}
          </Row>
          <CustomModal
            title={this.props.t("profile_reward_status")}
            tabs={tabs}
            show={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            onTabChange={(key) => this.onTabChange(key)}
            activeTab={this.state.activeTab}
            // loadMoreAction={this.loadMore}
          />
        </Container>
      );
    }

    return (
      <>
        {this.isMobile
          ? RewardStatusMobile()
          : RewardStatusDesktop()
        }
      </>
    )
  }
}

export default withRouter(withTranslation()(RewardStatusSection));
