import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import i18n from 'I18n';

import './GenderPicker.scss';

const GenderPicker = ({ customClass = '', sections, value, selectValue }) => {
  const [selectedGender, setSelectedGender] = useState(value);
  const [showGenderOptions, setShowGenderOptions] = useState(false);

  const displayValue = sections
    .filter((item) => item.pk === selectedGender)
    .map((item) => item?.name)?.[0];

  return (
    <div>
      <Dropdown
        style={{ width: '100%' }}
        onToggle={(isOpen) => {
          setShowGenderOptions(isOpen);
        }}
        onSelect={(gender) => {
          if (gender === selectedGender) {
            return;
          }
          selectValue(gender);
          setSelectedGender(gender);
        }}
      >
        <Dropdown.Toggle as="div" className={`picker-box ${customClass}`}>
          <div className="account-input field-text">
            {displayValue || i18n.t('personal_info_i_am')}
            <img
              alt="down"
              className="account-down-arrow"
              src={
                showGenderOptions
                  ? '/myAccount/up-arrow.png'
                  : '/myAccount/down-arrow.png'
              }
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="gender-dropdown">
          {sections?.map((item) => (
            <Dropdown.Item
              className="gender-dropdown-item field-text"
              eventKey={item?.pk || ''}
              active={selectedGender === item?.pk}
            >
              {item?.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default GenderPicker;
