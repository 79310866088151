import React from 'react';
import { Container, Image, Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';
import compareVersions from 'compare-versions';

import CustomAlert from 'component/base/CustomAlert';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction } from 'utils';

import './Navigator.scss';

import { ReactComponent as LeftArrow } from 'assets/back-btn.svg';
import { ReactComponent as MenuDropdown } from 'assets/images/menu.svg';
import { ReactComponent as MenuDropdownClosed } from 'assets/images/menuClose.svg';

const { detect } = require('detect-browser');
const browser = detect();
const minBrowserVersions = {
  chrome: '83',
  firefox: '78',
  ie: '11',
  safari: '12.1',
};
let starty;
let mobileNavDropDownScrollStatus = 0;

const TOP_LEFT_DISPLAY_TYPE = {
  logo: 'logo',
  text: 'welcome',
  text_hello: 'hello',
};
@connect(({ users, inboxMessages, navBars }) => ({
  users: users,
  messageUnreadCount: inboxMessages.unReadCount,
  pageBackBtnAction: navBars.backBtnAction,
}))
class Navigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileDropDown: false,
      showWebDropDown: false,
      showLogoutDialog: false,
    };

    // this.dropdownNav = React.createRef();
    this.setDropdownNav = this.setDropdownNav.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    this.topLeftDisplay =
      TOP_LEFT_DISPLAY_TYPE?.[params.get('left_top_display')] ||
      TOP_LEFT_DISPLAY_TYPE;
    this.topLeftText = params.get('top_left_text') || '';
  }
  goback = () => {
    window.history.back();
  };

  componentDidMount() {
    this.navBarNode.addEventListener('touchmove', this.preventTouchMove, {
      passive: false,
    });

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setDropdownNav(node) {
    this.dropdownNav = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.dropdownNav && !this.dropdownNav.contains(event.target)) {
      // console.log("You clicked outside of me!");
      this.setState((oldState) => ({
        showWebDropDown: !oldState.showWebDropDown,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      this.props.history.location.pathname !== '/browser-support'
    ) {
      if (!browser) {
        this.props.history.push('/browser-support');
      } else if (minBrowserVersions[browser.name]) {
        if (
          compareVersions(browser.version, minBrowserVersions[browser.name]) < 0
        ) {
          this.props.history.push('/browser-support');
        }
      }
    }
  }

  componentWillUnmount() {
    this.navBarNode.removeEventListener('touchmove', this.preventTouchMove, {
      passive: false,
    });

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  preventTouchMove = (event) => {
    if (event.cancelable) {
      event.preventDefault();
    }
  };

  bindHandleTouchstart = (event) => {
    starty = event.touches[0].pageY;
  };

  bindHandleTouchmove = (event) => {
    if (!this.props.users.isLogin) {
      event.preventDefault();
      return;
    }
    let endy;
    endy = event.changedTouches[0].pageY;
    let direction = this.getDirection(starty, endy);
    switch (direction) {
      case 0:
        event.preventDefault();
        break;
      case 1:
        if (mobileNavDropDownScrollStatus === 1 && event.cancelable) {
          event.preventDefault();
        }
        break;
      case 2:
        if (mobileNavDropDownScrollStatus === 2 && event.cancelable) {
          event.preventDefault();
        }
        break;
      default:
    }
  };

  bindHandleScroll = (event) => {
    const clientHeight = event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;
    const isBottom = clientHeight + scrollTop === scrollHeight;
    const isTop = scrollTop === 0;
    if (isTop) {
      mobileNavDropDownScrollStatus = 1;
    } else if (isBottom) {
      mobileNavDropDownScrollStatus = 2;
    } else {
      mobileNavDropDownScrollStatus = 0;
    }
  };

  getDirection = (starty, endy) => {
    var result = endy - starty;
    if (result === 0) {
      return 0;
    } else if (result > 0) {
      return 1;
    } else {
      return 2;
    }
  };

  mobileDropDownDidClick = () => {
    if (this.state.showMobileDropDown) {
      window.removeEventListener('touchstart', this.bindHandleTouchstart);
      window.removeEventListener('touchmove', this.bindHandleTouchmove, {
        passive: false,
      });
      this.dropDownMenutNode.removeEventListener(
        'touchmove',
        this.bindHandleScroll,
        { passive: false },
      );
    } else {
      window.addEventListener('touchstart', this.bindHandleTouchstart);
      window.addEventListener('touchmove', this.bindHandleTouchmove, {
        passive: false,
      });
      this.dropDownMenutNode.addEventListener('scroll', this.bindHandleScroll, {
        passive: false,
      });
    }

    this.setState((oldState) => ({
      showMobileDropDown: !oldState.showMobileDropDown,
    }));
  };

  webDropDownDidClick = () => {
    this.setState((oldState) => ({
      showWebDropDown: !oldState.showWebDropDown,
    }));
  };

  pushTo = (pathName, event) => {
    this.props.history.push(pathName);
    event();
  };

  getInboxUnreadCount = () => {
    const messageUnreadCount = this.props.messageUnreadCount;
    if (messageUnreadCount > 99) {
      return ' (99+)';
    }
    if (messageUnreadCount <= 0) {
      return '';
    }
    return ` (${messageUnreadCount})`;
  };

  commonNavItems = (withSperateLine = false) => {
    let commonItemsData = [
      {
        name: 'home_home',
        imageUrl: '/menu/home.png',
        linkTo: '/home',
        srcSet: '/menu/home@2x.png 2x, /menu/home@3x.png 3x',
      },
      {
        name: 'home_offers',
        imageUrl: '/menu/offer@.png',
        linkTo: '/offer/LATEST',
        srcSet: '/menu/offer@2x.png 2x, /menu/offer@3x.png 3x',
      },
      {
        name: 'about_upper',
        imageUrl: '/menu/about.png',
        linkTo: '/about',
        srcSet: '/menu/about@2x.png 2x, /menu/about@3x.png 3x',
      },
    ];

    if (this.props.users.isLogin) {
      // Egl hide rewards claim
      // commonItemsData.splice(2, 0, {
      //   name: 'points_claim',
      //   imageUrl: '/menu/cart.png',
      //   linkTo: '/transaction',
      //   srcSet: '/menu/cart@2x.png 2x, /menu/cart@3x.png 3x',
      // });
      commonItemsData.splice(2, 0, {
        name: 'home_inbox',
        imageUrl: '/menu/inbox.png',
        linkTo: '/inbox',
        srcSet: '/menu/inbox@2x.png 2x, /menu/inbox@3x.png 3x',
      });
    }

    return commonItemsData.map((data, index) => (
      <div
        key={index}
        className="nav_dropDownMenu_item_container"
        onClick={() => this.pushTo(data['linkTo'], this.mobileDropDownDidClick)}
      >
        <Container className="nav_dropDownMenu_item">
          <Image
            className="dropDownItem_image"
            src={data['imageUrl']}
            srcSet={data?.['srcSet'] || ''}
          />
          <div className="dropDownItem_text field-text">
            {this.props.t(data['name'])}
            {data['name'] === 'home_inbox' && this.getInboxUnreadCount()}
          </div>
        </Container>
        {withSperateLine ? this.separateLine(index) : null}
      </div>
    ));
  };

  getProfileItemsData = () => {
    let profileItemsData = [
      {
        name: 'my_profile',
        imageUrl: '/menu/myProfile.png',
        linkTo: '/user/myProfile',
        srcSet: '/menu/myProfile@2x.png 2x, /menu/myProfile@3x.png 3x',
      },
      {
        name: 'my_card',
        imageUrl: '/menu/myCard.png',
        linkTo: '/user/myCard',
        srcSet: '/menu/myCard@2x.png 2x, /menu/myCard@3x.png 3x',
      },
      {
        name: 'profile_my_coupons',
        imageUrl: '/menu/myCoupons.png',
        linkTo: '/user/myCoupon',
        srcSet: '/menu/myCoupons@2x.png 2x, /menu/myCoupons@3x.png 3x',
      },
      {
        name: 'profile_my_account',
        imageUrl: '/menu/myAccount.png',
        linkTo: '/user/myAccount',
        srcSet: '/menu/myAccount@2x.png 2x, /menu/myAccount@3x.png 3x',
      },
    ];
    return profileItemsData;
  };

  getMobileProfileNavItems = () => {
    let profileItemsData = this.getProfileItemsData();
    let commonItemsDataLength = this.commonNavItems().length;
    return profileItemsData.map((data, index) => (
      <div
        key={index + commonItemsDataLength + 1}
        className="nav_dropDownMenu_item_container"
        onClick={() => this.pushTo(data['linkTo'], this.mobileDropDownDidClick)}
      >
        <Container className="nav_dropDownMenu_item">
          <Image
            className="dropDownItem_image"
            src={data['imageUrl']}
            srcSet={data?.['srcSet'] || ''}
          />
          <div className="dropDownItem_text field-text">
            {this.props.t(data['name'])}
            {data['name'] === 'home_inbox' && this.getInboxUnreadCount()}
          </div>
        </Container>
      </div>
    ));
  };

  getWebProfileNavItems = () => {
    let profileItemsData = this.getProfileItemsData();
    let items = [];
    let reduceNum = 0;

    for (let index = 0; index < profileItemsData.length + 1; index++) {
      if (index === 4) {
        items.push(this.separateLine(index));
        reduceNum += 1;
      } else {
        let profileIndex = index - reduceNum;
        items.push(
          <div
            key={index}
            className="nav_dropDownMenu_item_container"
            onClick={() =>
              this.pushTo(
                profileItemsData[profileIndex]['linkTo'],
                this.webDropDownDidClick,
              )
            }
          >
            <Container className="nav_dropDownMenu_item">
              <Image
                className="dropDownItem_image"
                src={profileItemsData[profileIndex]['imageUrl']}
                srcSet={profileItemsData[profileIndex]?.['srcSet'] || ''}
              />
              <div className="dropDownItem_text field-text">
                {this.props.t(profileItemsData[profileIndex]['name'])}
              </div>
            </Container>
          </div>,
        );
      }
    }

    return items;
  };

  separateLine = (key) => {
    return (
      <div key={key} className="nav_dropDownMenu_separateLine_container">
        <Container className="nav_dropDownMenu_separateLine">
          <div className="separateLine_color" />
        </Container>
      </div>
    );
  };

  loginLogoutNavItem = (key) => {
    if (this.props.users?.isOpenInOtherSite) {
      return;
    }
    return (
      <div key={key} className="nav_dropDownMenu_item_container">
        <Container
          className="nav_dropDownMenu_item"
          onClick={this.onShowLogoutDialog}
        >
          <div className="dropDownItem_text field-text">
            {this.props.users.isLogin
              ? this.props.t('account_logout')
              : this.props.t('login')}
          </div>
        </Container>
        <div className="dropDownItem_blankArea" />
      </div>
    );
  };

  loginLogoutNavItemDidClicked = () => {
    if (this.props.users.isLogin) {
      this.props.dispatch(createAction('users/logout')());
      if (this.state.showMobileDropDown) {
        this.mobileDropDownDidClick();
      }
      if (this.state.showWebDropDown) {
        this.webDropDownDidClick();
      }
      this.props.history.push('/home');
    } else {
      window.location.href = ssoAPIHelper.getLoginWebUri(
        this.props.i18n?.language || 'en',
      );
    }
  };

  getMobileDropDownMenuItems = () => {
    let commonNavItems = this.commonNavItems(!this.props.users.isLogin);
    let getDropDownMenuItems = [];
    getDropDownMenuItems = getDropDownMenuItems.concat(commonNavItems);

    if (this.props.users.isLogin) {
      getDropDownMenuItems.push(this.separateLine(getDropDownMenuItems.length));

      let profileNavItems = this.getMobileProfileNavItems();
      getDropDownMenuItems = getDropDownMenuItems.concat(profileNavItems);

      getDropDownMenuItems.push(this.separateLine(getDropDownMenuItems.length));
    } else {
      return (
        <div className="mobile-signup-login-area">
          <img className="signup-login-logo" alt="" src={'/logo/profile_logo_image.png'} />
          {!this.props.users?.isOpenInOtherSite ? (
            <div className="signup-login-button-area">
              <button className='button-large' onClick={this.loginLogoutNavItemDidClicked}>
                {i18n.t('profile_get_started_sign_up_or_log_in')}
              </button>
            </div>
          ) : null}
          <div>{getDropDownMenuItems}</div>
        </div>
      );
    }
    getDropDownMenuItems.push(
      this.loginLogoutNavItem(getDropDownMenuItems.length),
    );
    return getDropDownMenuItems;
  };

  getWebDropDownMenuItems = () => {
    let profileNavItems = this.getWebProfileNavItems();
    profileNavItems.push(this.loginLogoutNavItem(profileNavItems.length));
    return profileNavItems;
  };

  changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

    this.props.dispatch({
      type: 'users/updateState',
      payload: { language: lang },
    });
  };

  translationSection = () => {
    return (
      <Nav.Item>
        <div className="nav_div_trans">
          <label
            className={i18n.language === 'en' ? 'active' : ''}
            onClick={() => {
              this.changeLanguage('en');
            }}
          >
            EN
          </label>{' '}
          /{' '}
          <label
            className={i18n.language === 'zh-Hant' ? 'active' : ''}
            onClick={() => {
              this.changeLanguage('zh-Hant');
            }}
          >
            繁
          </label>
          {' '}
          /{' '}
          <label
            className={i18n.language === 'zh-Hans' ? 'active' : ''}
            onClick={() => {
              this.changeLanguage('zh-Hans');
            }}
          >
            简
          </label>
        </div>
      </Nav.Item>
    );
  };

  onShowLogoutDialog = () => {
    this.setState({ showLogoutDialog: true });
  };

  onHiddenLogoutDialog = () => {
    this.setState({ showLogoutDialog: false });
  };

  rightBtnAction = () => {
    this.onHiddenLogoutDialog();
    this.loginLogoutNavItemDidClicked();
  };

  logoImage = ({ className }) => {
    return (
      <img 
        alt=""
        className={className}
        src='/logo/logo_mobile@3x.png'
        onClick={ ()=> window.open("https://www.egltours.com", "_blank", "noopener noreferrer") }
      />
    )
  }

  render() {
    if (this.props.location.pathname.indexOf('/unsubscribe_email') !== -1) {
      return (
        <Container className="nav_container">
          { this.logoImage({ className: "float-left logo" }) }
          <Nav
            ref={(node) => (this.navBarNode = node)}
            className="justify-content-end d-flex d-md-none nav_bar"
          ></Nav>
        </Container>
      );
    }

    let offerNavItemActive = '';
    if (this.props.location.pathname.indexOf('/offer/') !== -1) {
      offerNavItemActive = 'active';
    }

    const { showLogoutDialog } = this.state;

    const isCouponDetailPage =
      this.props.location.pathname.indexOf('/coupon/') !== -1;
    const isUseCoupon =
      this.props.location.pathname.indexOf('/use_coupon') !== -1;
    const isCampaignDetailPage =
      this.props.location.pathname.indexOf('/campaign/') !== -1;
    const isInboxDetailPage =
      this.props.location.pathname.indexOf('/inboxDetail/') !== -1;
    const isLevelRuleMobilePage =
      this.props.location.pathname.indexOf('/level_rule') !== -1;
    const isLevelBenefitMobilePage =
      this.props.location.pathname.indexOf('/level_benefit') !== -1;
    const isRewardStatusMobilePage =
      this.props.location.pathname.indexOf('/reward_status') !== -1;
    const isActivityLogMobilePage =
      this.props.location.pathname.indexOf('/activity_log') !== -1;
    const isTransactionHistoryMobilePage =
      this.props.location.pathname.indexOf('/transaction_history') !== -1;
    const isTransactionDetailPage =
      this.props.location.pathname.indexOf('/transactionDetail') !== -1;
    const isCreateRewardClaimPage =
      this.props.location.pathname.indexOf('/transaction/create') !== -1;
    const isUseCashDollarPage =
      this.props.location.pathname.indexOf('/use_cash_dollar') !== -1;
    const showBackButton =
      isCouponDetailPage ||
      isUseCoupon ||
      isCampaignDetailPage ||
      isInboxDetailPage ||
      isLevelRuleMobilePage ||
      isLevelBenefitMobilePage ||
      isRewardStatusMobilePage ||
      isActivityLogMobilePage ||
      isTransactionHistoryMobilePage ||
      isTransactionDetailPage ||
      isCreateRewardClaimPage ||
      isUseCashDollarPage;

    const renderTopLeftContent = () => {
      if (
        this.topLeftDisplay === TOP_LEFT_DISPLAY_TYPE.text_hello &&
        this.props.users.isLogin
      ) {
        return (
          <div className="float-left top-left-text">
            <div>{i18n.t('home_hello') + '!'}</div>
            <div>{this.props.users?.displayName}</div>
          </div>
        );
      }
      if (this.topLeftDisplay === TOP_LEFT_DISPLAY_TYPE.text) {
        return (
          <div className="float-left top-left-text">
            <div>{this.topLeftText}</div>
          </div>
        );
      }
      return (
        <>
          { this.logoImage({ className: `float-left logo ${showBackButton ? 'hide_logo' : ''}` }) }
          {showBackButton && (
            <LeftArrow
              className={
                showBackButton ? 'float-left navigator_back_btn ' : ' hide_logo'
              }
              onClick={() => {
                if (
                  this.props.pageBackBtnAction &&
                  typeof this.props.pageBackBtnAction === 'function'
                ) {
                  this.props.pageBackBtnAction();
                } else if (!this.props.location.key) {
                  this.props.history.push('/home');
                } else {
                  this.props.history.goBack();
                }
              }}
            />
          )}
        </>
      );
    };

    return (
      <Container className="nav_container subheading">
        {renderTopLeftContent()}
        <Nav
          ref={(node) => (this.navBarNode = node)}
          className="justify-content-end d-flex d-md-none nav_bar"
        >
          {/* {this.translationSection()} */}
          {this.state.showMobileDropDown ? (
            <MenuDropdownClosed
              className="dropDown_image"
              onClick={this.mobileDropDownDidClick}
            />
          ) : (
            <MenuDropdown
              className="dropDown_image"
              onClick={this.mobileDropDownDidClick}
            />
          )}
        </Nav>
        <div
          ref={(node) => (this.dropDownMenutNode = node)}
          className="nav_mobile_dropDownMenu d-md-none"
          style={{ display: this.state.showMobileDropDown ? 'flex' : 'none' }}
        >
          <div className="nav_dropDownMenu_content">
            {this.getMobileDropDownMenuItems()}
          </div>
        </div>
        <Nav className="justify-content-end d-none d-md-flex nav_bar">
          <Nav.Item>
            <LinkContainer to="/home">
              <div className="nav_div">{this.props.t('home_home')}</div>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/offer/LATEST">
              <div className={'nav_div ' + offerNavItemActive}>
                {this.props.t('home_offers')}
              </div>
            </LinkContainer>
          </Nav.Item>
          {/* {this.props.users.isLogin && (
            <Nav.Item>
              <LinkContainer to="/transaction">
                <div className="nav_div">{this.props.t('points_claim')}</div>
              </LinkContainer>
            </Nav.Item>
          )} */}
          <Nav.Item>
            <LinkContainer to="/about">
              <div className="nav_div">{this.props.t('about_upper')}</div>
            </LinkContainer>
          </Nav.Item>
          {this.props.users.isLogin && (
            <Nav.Item>
              <LinkContainer to="/inbox">
                <div className="nav_div">
                  {this.props.t('home_inbox')} {this.getInboxUnreadCount()}
                </div>
              </LinkContainer>
            </Nav.Item>
          )}
          {this.props.users.isLogin && (
            <Nav.Item>
              <div className="nav_div" onMouseEnter={this.webDropDownDidClick}>
                {this.props.t('home_hello') +
                  ', ' +
                  this.props.users?.displayName}
              </div>
            </Nav.Item>
          )}
          {!this.props.users.isLogin && (
            <Nav.Item>
              <div
                className="nav_btn signup"
                onClick={this.loginLogoutNavItemDidClicked}
              >
                {this.props.t('sign_up_login')}
              </div>
            </Nav.Item>
          )}
          {/* <Nav.Item>
            <div className="nav_div_trans"> | </div>
          </Nav.Item>
          {this.translationSection()} */}
          {this.state.showWebDropDown && (
            <div
              className="nav_web_dropDownMenu d-none d-md-flex"
              ref={this.setDropdownNav}
            >
              {this.getWebDropDownMenuItems()}
            </div>
          )}
        </Nav>
        <CustomAlert
          show={showLogoutDialog}
          onHide={this.onHiddenLogoutDialog}
          message={i18n.t('confirm_log_out')}
          leftbtn={i18n.t('log_out_cancel')}
          leftAction={this.onHiddenLogoutDialog}
          rightbtn={i18n.t('log_out')}
          rightAction={this.rightBtnAction}
          isLoading={false}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(Navigator));
