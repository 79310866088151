import React, { useEffect, useState } from 'react';

import './CustomSwitchButton.scss';

function CustomSwitchButton({
  onChange = () => {},
  defaultChecked = false,
  disabled = false,
}) {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <label className="custom-switch-button">
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onClick={() => {
          onChange(!checked);
          setChecked(!checked);
        }}
        onChange={() => {}}
      />
      <span className="custom-switch-button-slider"></span>
    </label>
  );
}

export default CustomSwitchButton;
