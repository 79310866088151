import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Switch, Route, useLocation, Link , Redirect} from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import StoreList from "container/StoreList";
import OfferFloatActionButton from "component/offer/OfferFloatActionButton";
import i18n from "I18n";
import CustomNavDropdown from "./CustomNavDropdown";

import "./AboutNavigator.scss";

const menuConfigList = [
  {
    titleID: 'profile_store_directory',
    title: i18n.t("profile_store_directory"),
    activeKey: 'store_directory',
    pathname: '/about/store',
  },
  {
    titleID: 'settings_app_about_lower',
    title: i18n.t("settings_app_about_lower"),
    activeKey: 'about_aillia',
    pathname: process.env.REACT_APP_ABOUT_APP_LINK,
    external: true,
  },
  {
    titleID: 'sign_up_terms_conditions',
    title: i18n.t("sign_up_terms_conditions"),
    activeKey: 'terms_conditions',
    pathname: process.env.REACT_APP_TERM_CONDITIONS_LINK,
    external: true,
  },
  {
    titleID: 'profile_privacy_conditions',
    title: i18n.t("profile_privacy_conditions"),
    activeKey: 'privacy_policy',
    pathname: process.env.REACT_APP_PRIVACY_LINK,
    external: true,
  },
  {
    titleID: 'FAQs',
    title: i18n.t("FAQs"),
    activeKey: 'FAQs',
    pathname: process.env.REACT_APP_FAQS_LINK,
    external: true,
  },
];

const mapStateToProps = state => {
  return {
    language: state.users.language,
  };
};
function AboutNavigator({
  language,
}) {
  const [activeKey, setActiveKey] = useState('store_directory');
  const [dropdownNavTitle, setDropdownNavTitle] = useState('profile_store_directory');
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    for(const item of menuConfigList) {
      if (item.pathname !== pathname) {
        continue;
      }
      setActiveKey(item.activeKey);
      setDropdownNavTitle(item.titleID);
      return;
    }
    setActiveKey('store_directory');
    setDropdownNavTitle('profile_store_directory');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (<>
    <div className="about_us_nav_desktop">
      <Container className="about_us_navigator">
        <Nav activeKey={activeKey} className="about_us_nav">
          {menuConfigList.map((navItem, index) => {
            const active = navItem.activeKey === activeKey;
            const offerNavItemClassName = active ? "nav-link active subheading" : "nav-link subheading";
            const appendedLanguageLink = `${navItem.pathname}?lang=${language}`
            return (
              <Nav.Item key={index}>
                <Link to={{ pathname: appendedLanguageLink }} target={navItem.external ? "_blank": "_self"} rel="noopener noreferrer">
                  <div className={offerNavItemClassName}>
                    {i18n.t(navItem.titleID)}
                  </div>
                </Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </Container>
    </div>
    <Container className="user_container">
      <CustomNavDropdown
        className="about-page-nav-dropdown"
        activeKey={activeKey}
        navDropdownTitle={i18n.t(dropdownNavTitle)}
        menuList={menuConfigList}
      />

      <Switch>
        <Route path="/about/store">
          <StoreList />
        </Route>
        <Route path="/about">
          <Redirect to="/about/store" />
        </Route>
      </Switch>
      <OfferFloatActionButton />
    </Container>
  </>);
}

export default connect(mapStateToProps)(AboutNavigator);
