import moment from "moment";
import i18n from "../I18n";

const oneDaySeconds = 60 * 60 * 24 * 1000;
export const getLeftDays = (currentDate, endDate) => {
  const currentDateObject = moment(currentDate);
  const endDateObject = moment(endDate);
  currentDateObject.set({ hour: 23, minute: 59, second: 59 });
  endDateObject.set({ hour: 23, minute: 59, second: 59 });
  const currentLastTime = currentDateObject.valueOf();
  const endLastTime = endDateObject.valueOf();
  return Math.ceil((endLastTime - currentLastTime) / oneDaySeconds);
};

export const getLeftDate = (currentDate, endDate) => {
  if (!endDate) {
    return null;
  }
  if (currentDate >= endDate) {
    return i18n.t("campaign_expired");
  }
  const leftDays = getLeftDays(currentDate, endDate);

  if (leftDays >= 6) {
    return null;
  }
  if (leftDays === 0) {
    return i18n.t("home_last_day");
  }
  const originalString = i18n.t("home_last_3_days");
  return originalString.replace("3", leftDays + 1);
};

export const getCurrentDate = () => {
  const date = new Date();
  return date.getTime();
};

export const convertDateToStamp = (parseDate) => {
  if (parseDate) {
    const date = new Date(parseDate);
    return date.valueOf();
  }

  return null;
};

export const getCampaignLeftDays = (endDate) =>
  getLeftDate(getCurrentDate(), convertDateToStamp(endDate));

const TimeFormats = {
  Base: {
    en: "D MMM, YYYY",
    tc: "YYYY年MM月DD日",
    sc: "YYYY年MM月DD日",
  },
  CouponDetail: {
    en: "DD MMM, YYYY HH:mm",
    tc: "YYYY年MM月DD日 HH:mm",
    sc: "YYYY年MM月DD日 HH:mm",
  },
  YearMonth: {
    en: "MMM, YYYY",
    tc: "YYYY年MM月",
    sc: "YYYY年MM月",
  }
};

const getDataFormattedDate = (time, defaultFormat, enFormat = null) => {
  let format = enFormat || defaultFormat.en;
  const { language } = i18n;
  if (language === "zh-Hant") {
    format = defaultFormat.tc;
  } else if (language === "zh-Hans") {
    format = defaultFormat.sc;
  }
  return moment(time).format(format);
};
export const getBaseFormattedDate = (time, enFormat) => {
  return getDataFormattedDate(time, TimeFormats.Base, enFormat);
};

export const getCouponDetailFormattedDate = (time) => {
  return getDataFormattedDate(time, TimeFormats.CouponDetail);
};

export const formatDate = (date, enFormat, withTime = false, timeWithComma = true) => {
  let format = enFormat || "D MMM, YYYY";
  const { language } = i18n;
  if (language === "zh-Hant") {
    format = "YYYY年MM月DD日";
  } else if (language === "zh-Hans") {
    format = "YYYY年MM月DD日";
  }

  if (withTime) {
    format += timeWithComma ? ", H:mm:ss" : " H:mm:ss";
  }

  return moment(date).format(format);
};

export const getCampaignDetailEndDate = (endDateString) => {
  if (endDateString === null) {
    return null;
  }
  const currentDate = getCurrentDate();
  const endDate = convertDateToStamp(endDateString);
  const leftDays = getLeftDays(currentDate, endDate);

  if (leftDays >= 6) {
    return i18n.t("campaignDetail:detailEndDate", {
      date: formatDate(endDate),
    });
  }
  if (endDate - currentDate <= 0) {
    return i18n.t("campaignDetail:end");
  }
  if (leftDays === 0) {
    return i18n.t("home_last_day");
  }
  return i18n.t("campaignDetail:detailDaysLeft", { days: leftDays + 1 });
};

export const getCurrentDateFormatted = (withTime = true, timeWithComma = true) => {
  // const timeFormat = format || "D MMM, YYYY, H:mm:ss";
  // return moment().format(timeFormat);
  return formatDate(new Date(), null, withTime, timeWithComma);
};
export const getCouponEffectiveDate = (effectiveDate) =>
  getCouponDetailFormattedDate(effectiveDate);

export const getCouponListExpireDate = (now, expireDate) => {
  const leftDays = getLeftDays(now, expireDate);
  if (expireDate === null) {
    return i18n.t("coupon:alwaysActive");
  }

  if (now > expireDate || leftDays > 6) {
    return getCouponDetailFormattedDate(expireDate);
  }
  if (leftDays === 0) {
    return i18n.t("home_last_day");
  }
  return i18n.t("coupon:daysLeft", { days: leftDays + 1 });
};

export const getCouponDetailExpireDate = (now, expireDate) => {
  if (expireDate === null) {
    return null;
  } else {
    return i18n.t("coupon:detailExpireDate", {
      date: getCouponDetailFormattedDate(expireDate),
    });
  }
};

export const getMessageTime = (messageTime) => formatDate(messageTime);

export const getMyAccountUserBirthdayForUpdate = (selectUserBirthday) =>
  moment(selectUserBirthday).format("YYYY-MM-DD");
export const getMyAccountUserBirthdayForDisplay = (selectUserBirthday) =>
  getDataFormattedDate(selectUserBirthday, TimeFormats.YearMonth)

export const getLevelRenewTime = (renewTime) => {
  if (!renewTime) {
    return '';
  }
  return formatDate(renewTime, 'D MMM YYYY');
};

export const getCampaignAvailableOnDate = (startDateString) => {
  if (startDateString === null) {
    return null;
  }
  const startDate = convertDateToStamp(startDateString);
  return getBaseFormattedDate(startDate)
};

export const getReceiptDate = (date) => formatDate(date);

export const getActivityLogDate = (activityLogTime) =>
  getBaseFormattedDate(activityLogTime);

export const getActivityLogDateTime = (activityLogTime) => {
  const logTime = moment(activityLogTime);
  // const now = moment();
  // const duration = moment.duration(now - logTime)
  // const diffHours = Math.floor(Math.abs(duration.asHours()));
  // const diffMinutes = Math.floor(Math.abs(duration.asMinutes()));
  const { language } = i18n;
  const formatter = language === 'en' ? 'DD MMM YYYY' : 'YYYY年MM月DD日';
  // if (diffHours === 0) {
  //   const translationKey = diffMinutes <= 1 ? 'minuteAgo' : 'minutesAgo'
  //   return `${logTime.format(formatter)} - ${diffMinutes}${i18n.t(translationKey)}`;
  // }
  // if (diffHours < 24) {
  //   const translationKey = diffHours <= 1 ? 'hourAgo' : 'hoursAgo'
  //   return `${logTime.format(formatter)} - ${diffHours}${i18n.t(translationKey)}`;
  // }
  return logTime.format(`${formatter} - HH:mm`);
};


export const getRewardStatusExpireDate = (expireDate) => {
  if (expireDate) {
    return `${getBaseFormattedDate(expireDate)}`;
  }
  return i18n.t('campaign_always_valid');
}
