import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import CustomButton from 'component/base/CustomButton';
import CustomSwitchButton from 'component/base/CustomSwitchButton';

import { createAction } from 'utils';

import './MyAccountDirectMarketing.scss';

const mapStateToProps = ({ users }) => {
  return {
    updateDirectMarketing: users.updateDirectMarketing,
    language: users.language,
    userInfo: users.userInfo
  };
};

const MyAccountDirectMarketing = (props) => {
  console.log('@MyAccountDirectMarketing', props);

  const dispatch = useDispatch();
  const [hasAgreedDirectMarketing, setHasAgreedDirectMarketing] = useState(
    props.userInfo?.hasAgreedDirectMarketing,
  );
  const [directMarketingViaEmail, setDirectMarketingViaEmail] = useState(
    props.userInfo?.directMarketingViaEmail,
  );

  const confirmUpdate = () => {
    dispatch(createAction('users/updateUserInfo')({ params: { direct_marketing_via_email: directMarketingViaEmail, has_agreed_direct_marketing: hasAgreedDirectMarketing }, isDirectMarketing: true }));
  }

  const changed = (!!hasAgreedDirectMarketing != !!props.userInfo?.hasAgreedDirectMarketing) || (!!directMarketingViaEmail != !!props.userInfo?.directMarketingViaEmail)

  return (
    <Container className="common-shadow-section-container account-direct-marketing-section">
      <div className="common-section-title title-medium-2">
        {props.t('account_direct_marketing')}
      </div>

      <Row>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="account-check-section d-flex flex-row justify-content-between align-items-center">
              <div className="common-item-title subheading">
                {props.t('account_direct_marketing')}
              </div>
              <CustomSwitchButton
                defaultChecked={hasAgreedDirectMarketing}
                onChange={(isChecked) => {
                  setHasAgreedDirectMarketing(isChecked);
                  setDirectMarketingViaEmail(isChecked);
                }}
              />
            </div>
            <div className="check-message-detail body-1">
              {props.t('personal_info_receive_marketing_reminder')}
            </div>
          </div>
          <div className="common-account-item">
            <div className="account-check-section d-flex flex-row justify-content-between align-items-center">
              <div className="common-item-value accept-receive-marketing-via-email field-text">
                {props.t('personal_info_receive_marketing_via_email')}
              </div>
              <CustomSwitchButton
                defaultChecked={directMarketingViaEmail}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setHasAgreedDirectMarketing(true);
                  }
                  setDirectMarketingViaEmail(isChecked);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <CustomButton
        btnClicked={confirmUpdate}
        disabled={!changed}
        text={props.t('account_update')}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(
  withTranslation()(MyAccountDirectMarketing),
);
