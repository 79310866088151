import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const options = {
  // order and from where user language should be detected
  order: ["querystring", "htmlTag"],
  // keys or params to lookup language from
  lookupQuerystring: "lang",
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement.lang,
};

const translations = {
  en: {
    translations: {
      ...require("./translations/en.json"),
    },
    campaignDetail: require("./translations/campaignDetail/en.json"),
    coupon: require("./translations/coupon/en.json"),
    product: require("./translations/product/en.json"),
    records: require("./translations/records/en.json"),
    store: require("./translations/store/en.json"),
  },
  "zh-Hant": {
    translations: {
      ...require("./translations/zh-hk.json"),
    },
    campaignDetail: require("./translations/campaignDetail/zh-hk.json"),
    coupon: require("./translations/coupon/zh-hk.json"),
    product: require("./translations/product/zh-hk.json"),
    records: require("./translations/records/zh-hk.json"),
    store: require("./translations/store/zh-hk.json"),
  },
  "zh-Hans": {
    translations: {
      ...require("./translations/zh-cn.json"),
    },
    campaignDetail: require("./translations/campaignDetail/zh-cn.json"),
    coupon: require("./translations/coupon/zh-cn.json"),
    product: require("./translations/product/zh-cn.json"),
    records: require("./translations/records/zh-cn.json"),
    store: require("./translations/store/zh-cn.json"),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: translations,
    /* default language when load the website in browser */
    lng: document.documentElement.lang,
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "zh-Hant",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
