import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';
import { encode } from 'base-64';
import stringify from 'json-stable-stringify';
import QRCode from 'qrcode.react';

import { createAction, enLocaleNumberFormatter } from 'utils';
import { NO_LEVEL, QRCODE_SHOW_RAW_MEMBERSHIP_ID } from 'utils/Config';

import './MyCard.scss';
import LogoImage from 'assets/images/logo.png';

const QR_CODE_REFRESH_INTERVAL = 5 * 60 * 1000;

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    userInfo: state.users.userInfo,
    displayName: state.users.displayName,
    level: state.users.level,
    levelName: state.users.levelName,
    availablePoints: state.users.availablePoints,
  };
};
function MyCard({
  language,
  userInfo,
  displayName,
  level,
  levelName,
  availablePoints,
}) {
  const [refreshDatetime, setRefreshDatetime] = useState(Date.now());
  const [codeDataString, setCodeDataString] = useState('code');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('users/fetchAvailablePoints')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setRefreshDatetime(Date.now());
    }, QR_CODE_REFRESH_INTERVAL);
    return () => {
      // cancel refresh qrcode
      if (!!intervalID) {
        clearInterval(intervalID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userInfo?.memberShipID || !refreshDatetime) {
      return;
    }
    const { uid, memberShipID } = userInfo;
    const codeContent = {
      uid,
      memberShipID,
      date: refreshDatetime,
    };
    // console.log('codeString', codeContent, refreshDatetime);
    const unEncodeString = stringify(codeContent);
    const encoded = encode(unEncodeString);
    const codeDataDict = {
      code: encoded,
    };
    if (!QRCODE_SHOW_RAW_MEMBERSHIP_ID) {
      codeDataDict.member_id = memberShipID;
    }
    const codeString = stringify(codeDataDict, function (a, b) {
      return b.key.localeCompare(a.key);
    });
    // console.log('codeString', codeString);
    setCodeDataString(codeString);
  }, [userInfo, refreshDatetime]);

  return (
    <Container className="my_card_container">
      <div
        className={`my-card-background level-${level}-color ${
          NO_LEVEL ? 'my-card-no-level' : ''
        }`}
      />
      <div className="my_card_content">
        <img
          alt="logo"
          src={!NO_LEVEL && level === 4 ? "/logo/logo.png" : LogoImage}
          className="my_card_logo"
        />
        <p
          className={`my_card_user_name ${
            level === 4 ? 'level-4-text-color' : ''
          } ${NO_LEVEL ? 'my-card-no-level-text' : ''} title-small`}
        >
          {displayName}
        </p>
        <p
          className={`my_card_user_id ${
            level === 4 ? 'level-4-text-color' : ''
          } ${NO_LEVEL ? 'my-card-no-level-text' : ''} body-2`}
        >{`ID: ${userInfo?.memberShipID}`}</p>
        <div className="my_card_qrcode_view">
          <QRCode value={codeDataString} renderAs="svg" />
        </div>
        <div
          className={`my_card_available_points ${
            NO_LEVEL ? 'my-card-no-level-text' : ''
          }`}
        >
          <p className='title-large'>{enLocaleNumberFormatter(availablePoints)} </p>
          <p className='headline'> {i18n.t('points')}</p>
        </div>
        {!NO_LEVEL && (
          <p
            className={`my_card_tier ${
              level === 4 ? 'level-4-text-color' : ''
            } subheading`}
          >
            {levelName}
          </p>
        )}
      </div>
    </Container>
  );
}

export default connect(mapStateToProps)(MyCard);
