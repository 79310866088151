import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Progress } from "antd";

import { createAction } from "utils";
import { getActionButonText } from "utils/CampaignUtil";
import { isMobile } from "utils/ScreenUtils";
import i18n from "I18n";

import stampGift from "assets/images/stampGift.png";
import "./OnlyForYouWebCard.scss";

@connect(({ campaignDetail }) => { })
class OnlyForYouWebCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _btndidClick = (event) => {
    if (isMobile()) {
      if (this.props.history) {
        this.props.dispatch(
          createAction("campaignDetail/getCampaignDetailAndGoRedeemPage")({
            campaignID: this.props.data.campaignId,
            callBackFunction: this._goRedeemPage,
          })
        );
      }
    } else {
      this._cardDidClick();
    }
    event.stopPropagation();
  };

  _goRedeemPage = (data) => {
    this.props.history.push("/campaign/detail", {
      campaign: data,
      campaignID: this.props.data.campaignId,
    });
  };

  _cardDidClick = () => {
    if (this.props.history) {
      this.props.history.push("/campaign/" + this.props.data.campaignId);
    }
  };

  render() {
    console.log("FOR YOU DATA", this.props.data)
    const campaginActionButtonText = getActionButonText(
      this.props.data.campaignType,
      this.props.data.requiredPoints,
      this.props.data.campaignStartDate
    )
    return (
      <div className="onlyForYou_card_container" onClick={this._cardDidClick}>
        <div className='onlyForYou_card_image_container'>
          <Image
            src={this.props.data.campaignSquarePhoto}
            className="onlyForYou_card_image"
          />
          {this.props.data.brandImage && (
            <Image
              src={this.props.data.brandImage}
              className="onlyForYou_card_avatar"
              roundedCircle={true}
            />
          )}
          {
            this.props.data.outOfStock
            ? <div className={'onlyForYou_card_dimmed'} />
            : null
          }
        </div>
        <div className="onlyForYou_card_describe_container">
          <div className={
            `onlyForYou_card_describe title-large ${window.innerWidth < 1200 ? "title-medium-2" : ""
            } ${window.innerWidth < 992 ? "title-small" : ""
            }`}>
            {this.props.data.campaignDescription}
          </div>
          {this.props.data.leftDays && (
            <div className={
              `onlyForYou_card_tag button-large ${window.innerWidth < 1200 ? "button-small" : ""
              } ${window.innerWidth < 992 ? "tag-menu-2" : ""
              }`}>
              {this.props.data.leftDays}
            </div>
          )}
          {this.props.data.outOfStock && (
            <div
              className={
                `onlyForYou_card_tag onlyForYou_outOfStock button-large ${window.innerWidth < 1200 ? "button-small" : ""
                } ${window.innerWidth < 992 ? "tag-menu-2" : ""}`
              }
            >
              {i18n.t("campaignDetail:campaign_card_out_of_stock_tag")}
            </div>
          )}
          {this.props.data.campaignActionButtonAvaiable &&
            campaginActionButtonText && (
              <Button
                className="onlyForYou_card_button button-large"
                onClick={this._btndidClick}
                disabled={!!this.props.data.outOfStock}
              >
                {campaginActionButtonText}
              </Button>
            )}
          {
            this.props.data.totalStamps && (
              <div className="onlyForYou_card_progress_container">
                <label className="subheading">{i18n.t('campaignDetail:campaignProgress')}</label>
                <div className="onlyForYou_card_progress">
                  <Progress
                    style={{
                      display: "flex",
                    }}
                    percent={Math.round(100 * this.props.data.earnedStamps / this.props.data.totalStamps)}
                    showInfo={false}
                    strokeWidth={10}
                  />
                  <div className="onlyForYou_card_gift_icon">
                    <img src={stampGift} className='onlyForYou_card_progress_img' alt={"Gift"} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(OnlyForYouWebCard);
