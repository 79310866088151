import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import i18n from 'I18n';
import Lottie from 'lottie-react';
import queryString from 'query-string';

import CustomButton from 'component/base/CustomButton';
import CustomSwitchButton from 'component/base/CustomSwitchButton';
import CustomAlert from 'component/base/CustomAlert';
import NotFoundPage from 'component/error/NotFound';

import { createAction } from 'utils';
import { checkLanguages } from 'utils/LanguageCheckUtil';

import './UnsubscribeEmail.scss';

import loadingJumpingBallsJson from 'assets/animation/loading_jumping_balls.json';

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    loadingError: state.unsubscribe.unsubscribeCustomerDetailError,
    loadingDetail: state.unsubscribe.unsubscribeCustomerDetailLoading,
    email: state.unsubscribe.unsubscribeCustomerDetail?.email,
    viaEmail:
      state.unsubscribe.unsubscribeCustomerDetail?.directMarketingViaEmail,
  };
};

const UnsubscribeEmail = ({
  language,
  loadingError,
  loadingDetail,
  email,
  viaEmail,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(createAction('unsubscribe/resetSubscribeEmail')());
    };
  }, []);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    const search = queryString.parse(location.search);
    const token = search?.token;
    if (!token) {
      setShowError(true);
    } else {
      setShowError(false);
      dispatch(
        createAction('unsubscribe/getCustomerSubscribeEmailDetail')({
          token,
        }),
      );
    }
    const newLanguage = checkLanguages(search?.locale);
    if (newLanguage !== language) {
      i18n.changeLanguage(newLanguage);

      dispatch({
        type: 'users/updateState',
        payload: {
          language: newLanguage,
        },
      });
    }
  }, [location.search]);

  const [directMarketingViaEmail, setDirectMarketingViaEmail] =
    useState(viaEmail);
  useEffect(() => {
    setDirectMarketingViaEmail(!!viaEmail);
  }, [viaEmail]);

  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  useEffect(() => {
    setUpdateButtonDisable(directMarketingViaEmail === viaEmail);
  }, [directMarketingViaEmail, viaEmail]);
  const updateBtnClicked = () => {
    const search = queryString.parse(location.search);
    const token = search?.token;
    setShow(true);
    setLoading(true);
    dispatch(
      createAction('unsubscribe/updateCustomerSubscribeEmail')({
        token,
        directMarketingViaEmail,
        resultCallback: () => {
          setLoading(false);
        },
      }),
    );
  };
  if (loadingDetail) {
    return (
      <Container className="user-section-container">
        <Lottie
          className="loading-jumping-balls"
          animationData={loadingJumpingBallsJson}
          loop
        />
      </Container>
    );
  }
  if (showError || loadingError) {
    return <NotFoundPage />;
  }
  return (
    <Container className="user-section-container">
      <Container className="common-shadow-section-container account-info-section">
        <div className="common-section-title title-medium-2">
          {i18n.t('account_direct_marketing')}
        </div>
        <div className="common-account-item">
          <div className="common-item-value field-text">
            {i18n.t('settings_email')}
            {': '}
            {email}
          </div>
        </div>
        <div className="common-account-item">
          <div className="account-check-section d-flex flex-row justify-content-start gap-4">
            <div className="common-item-value field-text">
              {i18n.t('personal_info_receive_marketing_via_email')}
            </div>
            <CustomSwitchButton
              defaultChecked={directMarketingViaEmail}
              onChange={(isChecked) => {
                setDirectMarketingViaEmail(isChecked);
              }}
            />
          </div>
          <div className="check-message-detail body-1">
            {i18n.t('personal_info_receive_marketing_via_email_reminder')}
          </div>
        </div>
        <CustomButton
          btnClicked={updateBtnClicked}
          disabled={updateButtonDisable}
          text={i18n.t('personal_info_receive_marketing_update_save')}
        />
        <CustomAlert
          show={show}
          title={
            loading
              ? null
              : i18n.t('personal_info_receive_marketing_update_saved')
          }
          leftbtn={''}
          rightbtn={i18n.t('personal_info_receive_marketing_done')}
          rightAction={() => {
            setShow(false);
          }}
          isLoading={loading}
        />
        <CustomAlert
          show={show}
          title={
            loading
              ? null
              : i18n.t('personal_info_receive_marketing_update_saved')
          }
          leftbtn={''}
          rightbtn={i18n.t('personal_info_receive_marketing_done')}
          rightAction={() => {
            setShow(false);
          }}
          isLoading={loading}
        />
      </Container>
    </Container>
  );
};

export default connect(mapStateToProps)(UnsubscribeEmail);
