import React, { Component } from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';
import Lottie from 'lottie-react';

import DownloadQRcode from 'component/campaign/DownloadQRcode';
import Scanner from 'component/campaign/Scanner';
import {
  EARNING_RULE_GPS,
  EARNING_RULE_QR_CODE,
  EARNING_RULE_REFER,
} from 'services/CampaignAPIHelper';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction, detectIsMobile } from 'utils';
import { checkIfExpired, checkIfStarted } from 'utils/CampaignUtil';
import { ONLY_HAVE_WEBSITE } from 'utils/Config';
import { getCampaignAvailableOnDate } from 'utils/DateTimeUtils';

import './CheckInSection.scss';

export const ACTION_BUTTON_STATUS = {
  NORMAL: 0,
  LOADING: 1,
  TICK: 2,
  DISABLE: 3,
  MYCOUPON: 4,
  UNAVAILABLE: 5,
  GET: 6,
};

@connect(({ users, campaignDetail }) => ({
  membershipID: users.userInfo.memberShipID,
  isLogin: users.isLogin,
  actionButtonStatus: campaignDetail.actionButtonStatus,
}))
class CheckInSection extends Component {
  constructor(props) {
    super(props);
    this.isMobile = detectIsMobile();
    this.state = {
      modalShow: false,
      showActionModal: false,
      showScanQRCode: false,
      qrCodeString: '',
    };
    this.appName = process.env.REACT_APP_NAME
  }
  componentDidUpdate = () => {
    if (this.state.qrCodeString) {
      this.props.dispatch(
        createAction('campaignDetail/verifyQrCode')({
          qrCodeString: this.state.qrCodeString,
          campaignID: this.props.campaignID,
        }),
      );
      this.setState({ qrCodeString: '' });
    }
  };
  earnedLabel = () => {
    if (this.props.earnRule.textTitle === 'COUPON') {
      return i18n.t('coupons_earned');
    } else if (this.props.earnRule.textTitle === 'POINTS EARN') {
      return i18n.t('points_earned');
    }
  };

  actionLabel = () => {
    if (!checkIfStarted(this.props.earnRule.startDate)) {
      return i18n.t('campaignDetail:availableOn', {
        date: getCampaignAvailableOnDate(this.props.earnRule.startDate),
      });
    }
    if (this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON) {
      return i18n.t('campaign_my_coupon');
    }
    if (this.props.earnRule.earningRuleType === EARNING_RULE_QR_CODE) {
      return i18n.t('campaignDetail:scanQrCode');
    } else if (this.props.earnRule.earningRuleType === EARNING_RULE_GPS) {
      return i18n.t('campaignDetail:checkIn');
    } else if (this.props.earnRule.earningRuleType === EARNING_RULE_REFER) {
      return i18n.t('campaignDetail:referAFriend');
    }

    return null;
  };

  setModalShowState = (status) => {
    this.setState({
      modalShow: status,
    });
  };

  getModalDescription = () => {
    if (this.props.earnRule.earningRuleType === EARNING_RULE_QR_CODE) {
      return i18n.t('campaignDetail:noCamera');
    } else if (this.props.earnRule.earningRuleType === EARNING_RULE_GPS) {
      return i18n.t('campaignDetail:useMobileDevice');
    } else if (this.props.earnRule.earningRuleType === EARNING_RULE_REFER) {
      return i18n.t('referMessage', {
        link: `${process.env.REACT_APP_DEEP_LINK_SCHEMA}/?referCode=${this.props.membershipID}`,
        code: this.props.membershipID,
      });
    }
    return null;
  };

  getGeo = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.props.dispatch(
          createAction('campaignDetail/verifyGPS')({
            campaignID: this.props.campaignID,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        );
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
          case error.POSITION_UNAVAILABLE:
          case error.TIMEOUT:
          default:
            this.props.dispatch(
              createAction('navBars/updateState')({
                toastShowing: {
                  value: true,
                  content: i18n.t('campaignDetail:invalidGPSLocation'),
                },
              }),
            );
        }
      },
    );
  };

  render() {
    const tickAnimation = () => {
      return (
        <Lottie
          animationData={require('../../assets/animation/tick_green.json')}
          loop={false}
          onComplete={() => {
            if (
              (this.props.earnRule.earningRuleType === EARNING_RULE_QR_CODE ||
                this.props.earnRule.earningRuleType === EARNING_RULE_GPS) &&
              this.props.earnRule?.rewardType === 'COUPON'
            ) {
              this.props.dispatch(
                createAction('campaignDetail/updateState')({
                  actionButtonStatus: ACTION_BUTTON_STATUS.MYCOUPON,
                }),
              );
            } else {
              this.props.dispatch(
                createAction('campaignDetail/updateState')({
                  actionButtonStatus: ACTION_BUTTON_STATUS.DISABLE,
                }),
              );
            }
          }}
        />
      );
    };

    const loadingAnimation = () => {
      return (
        <Lottie
          animationData={require('../../assets/animation/loading_jumping_balls.json')}
          loop={true}
        />
      );
    };

    const showActionButton = () => {
      return (
        <button
          className={`check_in_button
            ${
              this.props.actionButtonStatus === ACTION_BUTTON_STATUS.TICK
                ? 'tick_animation_btn'
                : ''
            }
            ${
              this.props.actionButtonStatus === ACTION_BUTTON_STATUS.LOADING
                ? 'loading_animation_btn'
                : ''
            }
            ${
              this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON
                ? 'my_coupon_btn'
                : ''
            }
          `}
          onClick={() => {
            if (!this.props.isLogin) {
              window.location.href = ssoAPIHelper.getLoginWebUri(
                this.props.i18n?.language || 'en',
              );
            } else if (this.props.inBlackoutPeriod) {
              this.props.dispatch(
                createAction('navBars/updateState')({
                  toastShowing: {
                    value: true,
                    content: i18n.t('campaignDetail:blackoutLimit'),
                  },
                }),
              );
            } else if (checkIfExpired(this.props.earnRule.endDate)) {
              this.props.dispatch(
                createAction('navBars/updateState')({
                  toastShowing: {
                    value: true,
                    content: i18n.t('campaignDetail:expireToast'),
                  },
                }),
              );
            } else if (
              this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON
            ) {
              this.props.history.push('/user/myCoupon');
            } else if (
              this.props.earnRule.earningRuleType === EARNING_RULE_QR_CODE &&
              this.isMobile
            ) {
              this.setState({
                showScanQRCode: true,
              });
            } else if (
              this.props.earnRule.earningRuleType === EARNING_RULE_GPS &&
              this.isMobile
            ) {
              this.getGeo();
            } else {
              this.setState({
                showActionModal: true,
              });
            }
          }}
          disabled={
            this.props.actionButtonStatus === ACTION_BUTTON_STATUS.DISABLE ||
            !checkIfStarted(this.props.earnRule.startDate)
          }
        >
          {this.props.actionButtonStatus === ACTION_BUTTON_STATUS.TICK ? (
            tickAnimation()
          ) : this.props.actionButtonStatus === ACTION_BUTTON_STATUS.LOADING ? (
            loadingAnimation()
          ) : (
            <label className="check_in_label button-large">{this.actionLabel()}</label>
          )}
        </button>
      );
    };

    const actionModalButton = () => {
      if (this.props.earnRule.earningRuleType === EARNING_RULE_REFER) {
        return (
          <>
            <button
              className="left_btn button-large"
              onClick={() => this.setState({ showActionModal: false })}
            >
              {i18n.t('close')}
            </button>
            <button
              className="right_btn button-large"
              onClick={() => {
                navigator.clipboard.writeText(
                  i18n.t('referMessage', {
                    link: `${process.env.REACT_APP_DEEP_LINK_SCHEMA}/?referCode=${this.props.membershipID}`,
                    code: this.props.membershipID,
                  }) || '',
                );
                this.setState({ showActionModal: false });
                this.props.dispatch({
                  type: 'navBars/updateState',
                  payload: {
                    toastShowing: {
                      value: true,
                      content: i18n.t('copySuccessfully'),
                      toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
                    },
                  },
                });
              }}
            >
              {i18n.t('copy')}
            </button>
          </>
        );
      }
      return (
        <>
          <button
            className="right_btn button-large"
            onClick={() => {
              this.props.dispatch(
                createAction('campaignDetail/updateState')({
                  actionButtonStatus: ACTION_BUTTON_STATUS.DISABLE,
                }),
              );
              this.setState({
                showActionModal: false,
              });
            }}
          >
            {i18n.t('campaign_ok')}
          </button>
        </>
      );
    };

    const showActionModal = () => {
      return (
        <Modal
          show={this.state.showActionModal}
          onHide={() => this.setState({ showActionModal: false })}
          className="use_coupon_modal"
          centered
        >
          <Modal.Body className="use_coupon_modal_content d-flex-column">
            <div>
              <p className="use_coupon_title title-medium-1">{this.getModalDescription()}</p>
            </div>
            <div className="button_group">{actionModalButton()}</div>
          </Modal.Body>
        </Modal>
      );
    };
    return (
      <>
        <Container className="check_in_section">
          <Row>
            <div className="flex-columns">
              <div className="point_earned subheading">
                {this.props.earnRule.textTitle}
              </div>
              <div className="points title-medium-2">{this.props.earnRule.point}</div>
            </div>
          </Row>
          {[EARNING_RULE_GPS, EARNING_RULE_QR_CODE, EARNING_RULE_REFER].indexOf(
            this.props.earnRule.earningRuleType,
          ) >= 0 && (
            <>
              <Row className="check_in_button_section">
                {showActionButton()}
              </Row>
              {ONLY_HAVE_WEBSITE ? null : (
                <Row className="remind_message">
                  <p className="campaign_avaliable_message field-text">
                    {i18n.t("campaign_suggest_use_mobile_device", { brandName: this.appName, appName: this.appName })}
                  </p>
                  <p
                    className="campaign_avaliable_message download_text subheading"
                    onClick={() => this.setModalShowState(true)}
                  >
                    {i18n.t("campaign_click_to_download_app", { appName: this.appName })}
                  </p>
                </Row>
              )}
            </>
          )}
        </Container>
        <DownloadQRcode
          show={this.state.modalShow}
          onHide={() => this.setModalShowState(false)}
        />
        {this.isMobile &&
        this.props.earnRule.earningRuleType === EARNING_RULE_QR_CODE &&
        this.state.showScanQRCode ? (
          <Scanner
            show={this.state.showScanQRCode}
            handleClose={() => {
              this.setState({ showScanQRCode: false });
            }}
            setQRCodeString={(code) => this.setState({ qrCodeString: code })}
            onError={(errorMessage) => {
              this.props.dispatch(
                createAction('navBars/updateState')({
                  toastShowing: {
                    value: true,
                    content: errorMessage,
                  },
                }),
              );
            }}
          />
        ) : (
          showActionModal()
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(CheckInSection));
