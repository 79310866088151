import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import i18n from 'I18n';

import ActionBottom from 'component/campaign/ActionBottomSection';
import Brand from 'component/campaign/Brand';
import CheckInSection, {
  ACTION_BUTTON_STATUS,
} from 'component/campaign/CheckInSection';
import DetailDescription from 'component/campaign/DetailDescription';
import ImageSlider from 'component/campaign/ImageSlider';
import Instruction from 'component/campaign/Instruction';
import PointRequiredSection from 'component/campaign/PointRequiredSection';
import SharedSection from 'component/campaign/SharedSection';
import ShortDescription from 'component/campaign/ShortDescription';
import StampSection from 'component/campaign/StampSection';
import Stock from 'component/campaign/Stock';
import { EARNING_RULE_PURCHASE } from 'services/CampaignAPIHelper';

import { createAction } from 'utils';
import { APP_NAME, DOWNLOAD_APP_DOMAIN, WEB_DOMAIN } from 'utils/Config';

import './CampaignDetail.scss';
import CustomAlert from 'component/base/CustomAlert';
import { isMobile, isPadOrMobile } from 'utils/ScreenUtils';

@connect(({ campaignDetail, users }) => ({
  ...campaignDetail,
  language: users.language,
  membershipID: users.userInfo.memberShipID,
  isLogin: users.isLogin,
}))
class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // showScanQRCodeModal: false,
      qrCodeString: '',
      showScanner: false,
      showUnavailable: false,
    };
  }

  componentDidMount() {
    this.campaignID =
      this.props.history.location.state?.campaignID ||
      this.props.match.params?.id;
    this.props.dispatch(
      createAction('campaignDetail/getCampaignDetail')({
        campaignID: this.campaignID,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(
        createAction('campaignDetail/getCampaignDetail')({
          campaignID: this.campaignID,
        }),
      );
    }
    if (prevProps.hasError !== this.props.hasError && this.props.hasError && !this.state.showUnavailable) {
      this.setState({ showUnavailable: true });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      createAction('campaignDetail/updateState')({
        actionButtonStatus: ACTION_BUTTON_STATUS.NORMAL,
      }),
    );
  }

  hideModal = () => {
    this.setState({ showUnavailable: false });
  };

  showImageSlider = () => {
    if (this.props[this.campaignID]) {
      return <ImageSlider className="campaign_detail_custom_image_slider" images={this.props[this.campaignID].data.images} />;
    } else {
      return <ImageSlider className="campaign_detail_custom_image_slider" images={[]} />;
    }
  };

  outOfStockTag = (noMargin=false) => {
    return this.props[this.campaignID].data.basic.outOfStock ? (
      <label className="offer_tag out_of_stock_tag" style={noMargin ? { marginLeft: 0 } : {}}>
        <div className={`offer_state ${isPadOrMobile() ? "tag-menu-2" : "button-large"}`}>{this.props.t('campaignDetail:OUT OF STOCK')}</div>
      </label>
    ) : null;
  };

  showTag = () => {
    if (this.props[this.campaignID]) {
      if (this.props[this.campaignID].data.basic.isFeatured) {
        return (
          <>
            <label className="featured_tag">
              <div className={`feature ${isPadOrMobile() ? "tag-menu-2" : "button-large"}`}>{this.props.t('featured_tag')}</div>
            </label>
            {this.outOfStockTag()}
          </>
        );
      } else if (this.props[this.campaignID].data.basic.isOnlyForYou) {
        return (
          <>
            <label className="offer_tag">
              <div className={`offer_state ${isPadOrMobile() ? "tag-menu-2" : "button-large"}`}>
                {this.props.t('home_only_for_you')}
              </div>
            </label>
            {this.outOfStockTag()}
          </>
        );
      } else {
        return this.outOfStockTag(true);
      }
    }
  };

  name = () => {
    if (this.props[this.campaignID]) {
      if (this.props[this.campaignID].data.basic.name) {
        return this.props[this.campaignID].data.basic.name;
      }
    }
  };

  showBrand = () => {
    if (this.props[this.campaignID]) {
      if (
        this.props[this.campaignID].data.brand &&
        this.props[this.campaignID].data.brand.shown
      ) {
        return <Brand brand={this.props[this.campaignID].data.brand}></Brand>;
      } else {
        return '';
      }
    }
  };

  showShortDescription = () => {
    if (this.props[this.campaignID]) {
      if (this.props[this.campaignID].data.summary) {
        return (
          <ShortDescription
            description={this.props[this.campaignID].data.summary}
          ></ShortDescription>
        );
      }
    }
  };

  renderMask = () => {
    return isMobile();
  };

  showInstruction = () => {
    if (this.props[this.campaignID]) {
      if (this.props[this.campaignID].data.instructions.shown) {
        return (
          <Instruction
            renderMask={this.renderMask()}
            instructions={this.props[this.campaignID].data.instructions}
          />
        );
      } else {
        return '';
      }
    }
  };

  hideDevidingLine = () => {
    if (this.props[this.campaignID]) {
      if (
        this.props[this.campaignID].data.instructions.shown &
        this.props[this.campaignID].data.description.shown
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  showDescription = () => {
    if (this.props[this.campaignID]) {
      if (this.props[this.campaignID].data.description.shown) {
        return (
          <DetailDescription
            renderMask={this.renderMask()}
            description={this.props[this.campaignID].data.description}
          />
        );
      }
    }
  };

  showRightSection = () => {
    if (this.props[this.campaignID]) {
      const campaignType = this.props[this.campaignID]?.data?.campaignType;
      const inBlackoutPeriod =
        this.props[this.campaignID]?.data?.inBlackoutPeriod;
      if (campaignType === 'COUPON_CAMPAIGN') {
        return (
          <PointRequiredSection
            coupon={this.props[this.campaignID].data.coupon}
            inBlackoutPeriod={inBlackoutPeriod}
            from="campaign"
            hasStore={false}
            selectedStore={''}
            campaignID={this.campaignID}
          />
        );
      } else if (
        campaignType === 'EARNING_CAMPAIGN' &&
        this.props[this.campaignID].actionBar?.earningRuleType &&
        this.props[this.campaignID].actionBar.earningRuleType !==
          EARNING_RULE_PURCHASE
      ) {
        return (
          <CheckInSection
            earnRule={this.props[this.campaignID].actionBar}
            inBlackoutPeriod={inBlackoutPeriod}
            campaignID={this.campaignID}
          />
        );
      } else if (campaignType === 'STAMP_CAMPAIGN') {
        return (
          <StampSection
            campaignID={this.campaignID}
            inBlackoutPeriod={inBlackoutPeriod}
          />
        );
      }
    }
  };

  showExpriyDate = () => {
    if (this.props[this.campaignID]) {
      return this.props[this.campaignID].data.basic.endDate;
    }
  };

  showActionBar = () => {
    if (
      this.props[this.campaignID] &&
      (this.props[this.campaignID].data.campaignType === 'COUPON_CAMPAIGN' ||
        (this.props[this.campaignID].data.campaignType === 'EARNING_CAMPAIGN' &&
          this.props[this.campaignID].actionBar.earningRuleType !==
            EARNING_RULE_PURCHASE))
    ) {
      return (
        <ActionBottom
          campaign={this.props[this.campaignID].data}
          campaignId={this.campaignID}
          actionBar={this.props[this.campaignID].actionBar}
        />
      );
    }
  };

  showStock = () => {
    // console.log("@@200: ", this.props[this.campaignID]);
    if (
      this.props[this.campaignID] &&
      this.props[this.campaignID].data.campaignType === 'COUPON_CAMPAIGN' &&
      this.props[this.campaignID]?.apiResult
        .coupon_campaign_type_should_show_coupon_stock
    ) {
      return (
        <>
          <Stock coupon={this.props[this.campaignID].data.coupon} />
          <Row className="dividing"></Row>
        </>
      );
    }
  };

  detectIsMobile = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const maxTouchPoints = navigator.maxTouchPoints;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    const matchMobileResult = toMatch.some((matchItem) => {
      return userAgent.match(matchItem);
    });
    if (matchMobileResult || (platform === 'MacIntel' && maxTouchPoints > 1)) {
      return true;
    } else {
      return false;
    }
  };

  openAppClicked = () => {
    const url = `${DOWNLOAD_APP_DOMAIN}/?page=campaignDetail&id=${this.campaignID}`;
    window.location = url;
  };

  showOpenAppBtn = () => {
    if (this.detectIsMobile() && this.props[this.campaignID]) {
      var sectionClassName = '';
      const campaignType = this.props[this.campaignID].data.campaignType;
      if (campaignType === 'COUPON_CAMPAIGN') {
        sectionClassName = 'open_app_section coupon_type';
      } else if (campaignType === 'EARNING_CAMPAIGN') {
        sectionClassName = 'open_app_section earn_type';
      } else {
        sectionClassName = 'open_app_section';
      }
      return (
        <Row className={sectionClassName}>
          <button className="open_app_btn button-large" onClick={this.openAppClicked}>
            {i18n.t('open_in_app', { appName: APP_NAME })}
          </button>
        </Row>
      );
    }
  };

  render() {
    const isPadOrMobileView = isPadOrMobile();
    const title = `${APP_NAME} - ${this.props[this.campaignID]?.data?.basic?.name}`;
    const url = `${WEB_DOMAIN}/campaign/${this.campaignID}?fromDeepLink=true`;
    const subject = `${APP_NAME} - ${this.props[this.campaignID]?.data?.basic?.name}`
    const message = `東瀛遊 - ${this.props[this.campaignID]?.data?.basic?.name}: \n ${url} `;
    return (
      <div className="user_container_background">
        <Container className="campaign_detail_container">
          <Row className="nav_row button-large">
            <Link to="/">{this.props.t('home_home')}</Link> {'>'}
            <Link to="/offer/LATEST">{this.props.t('home_offers')}</Link>
            {'>'}
            <span className='nav_row_span'>{this.props.t('campaign_detail')}</span>
          </Row>
          <Row>
            <Col className="d-md-block left_section">
              <Row>{this.showImageSlider()}</Row>
              {this.showTag()}
              <Row>
                <div className={`offer_title ${isPadOrMobileView ? "headline" : "title-large"}`}>{this.name()}</div>
              </Row>
              <label
                className={`expiry_date ${isPadOrMobileView ? "body-2" : "field-text"}`}
                hidden={
                  this.props[this.campaignID] &&
                  this.props[this.campaignID].data.basic.endDate
                    ? false
                    : true
                }
              >
                {this.showExpriyDate()}
              </label>
              <Row
                className="dividing top_dividing"
                hidden={
                  this.props[this.campaignID] &&
                  this.props[this.campaignID].data.campaignType ===
                    'COUPON_CAMPAIGN'
                    ? false
                    : true
                }
              ></Row>
              {this.showStock()}
              {this.showBrand()}
              <Row>{this.showShortDescription()}</Row>
              <Row>{this.showInstruction()}</Row>
              <Row
                className="dividing_line campaign_dividing_line"
                hidden={this.hideDevidingLine()}
              ></Row>
              <Row>{this.showDescription()}</Row>
              {this.props[this.campaignID] &&
              this.props[this.campaignID].data.campaignType ===
                'STAMP_CAMPAIGN' ? (
                <div className="stamp-section-left">
                  <StampSection
                    campaignID={this.campaignID}
                    inBlackoutPeriod={this.props[this.campaignID].data.inBlackoutPeriod}
                  />
                </div>
              ) : (
                <></>
              )}
              <Row className="share_block container">
                <SharedSection title={title} message={message} />
              </Row>
              <Row className="fixed-bottom mobile_bottom_bar">
                {this.showActionBar()}
              </Row>
            </Col>

            <Col className=" col-md-4 right_section">
              <div className="float_right_section">
                {this.showRightSection()}
                <SharedSection title={title} message={message} />
              </div>
            </Col>
          </Row>
          <CustomAlert
            show={this.state.showUnavailable}
            onHide={this.hideModal}
            title={this.props.t('campaignDetail:campaignUnavailable')}
            message={''}
            leftbtn={''}
            rightbtn={this.props.t('campaign_ok')}
            rightAction={() => {
              this.hideModal();
              this.props.history.push('/home');
              this.props.dispatch(
                createAction('campaignDetail/updateState')({
                  hasError: false,
                }),
              );
            }}
          />
        </Container>
      </div>
    );
  }
}

export default withTranslation()(CampaignDetail);
